import * as React from 'react'
import { Component } from 'react'
import { FormattedMessage } from "react-intl";
import { Row } from 'antd-min';
import { SchoolLocale, GSAdminLocale } from '@app/locales/localeid';
import { PathConfig } from '@app/config/pathconfig';
import * as H from "history";
import { fmtMsg } from '@app/util';

interface RegisterSuccessProps { 
    email: string;
    phone: string;
    history: H.History;
 }
interface RegisterSuccessStates {}


export class RegisterSuccessPage extends Component<RegisterSuccessProps, RegisterSuccessStates> {
    email = this.props.email
    phone = this.props.phone;
    
    constructor(props) {
        super(props);
    }

    redirectToDashboard() {
        this.props.history.push({ pathname: PathConfig.Dashboard});
    }

    renderSuccessMsg = () => {
        const text = fmtMsg(
            { id: GSAdminLocale.RegistrationFinishMsg },
            { link: "&&##" }
        );
        const textArr = text.split('&&##');
        
        return (
            <Row style={{ marginTop: "-5px", marginBottom: "20px" }}>
                {textArr[0]}<a onClick={this.redirectToDashboard.bind(this)}>{textArr[1]}</a>{textArr[2]}
            </Row>
        );
    }

    render() {
        return <Row className="registersuccess">
            <Row>{this.renderSuccessMsg()}</Row>
        </Row>
    }
}
